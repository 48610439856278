import React, { useEffect } from "react";
import logger from "./../logger.js";
import clsx from "clsx";
import {
  Routes,
  Route,
  Link,
  BrowserRouter,
  withRouter,
  useLocation
} from "react-router-dom";

import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";
import store from "./../Common/storage.js";
import HistoryIcon from "@material-ui/icons/History";
import SmsIcon from "@material-ui/icons/Sms";
import MailIcon from "@material-ui/icons/Mail";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import HomeIcon from "@material-ui/icons/Home";
import TimelineIcon from "@material-ui/icons/Timeline";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";

import { getUser, removeUserSession } from "./../Utils/Common";
import Constants from "./../Utils/Constants";

import Icon from "./../assets/components/icon";

import SMSTable from "./SMSTable";
import HistoryTable from "./HistoryTable";
import SMSCampaignStepper from "./SMSCampaignStepper";
import CampaignHistory from "./CampaignHistory";
import Conversations from "./Conversations";
import Settings from "./Settings";
import AccountSummary from "./AccountSummary";

import useStyles from "./config/drawerconfig";
import DrawerLogo from "./DrawerLogo";


import "./index.css";

const classNameActive = "c-sidebar__nav-link--active";
const classNameInactive = "c-sidebar__nav-link";

function MiniDrawer(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(Constants.dashboard);

  const user = getUser();

  const location = useLocation();

  useEffect(() => {
    if(!user) {
      Constants.navigate(Constants.login);
    }
    setSelected(window.location.pathname)
  }, [location])



  const toggleDrawerOpenClose = () => {
    setOpen(!open);
  };

  const drawers1 = (store.get('user').configs[store.get("c_id_index")]?.api_key == '' || store.get('user').configs[store.get("c_id_index")]?.api_key == '') ? [
    {
      title: "Logout",
      type: "button",
      icon: <ExitToAppIcon />,
      onClick: () => {
        // logger.log("onClick");
        removeUserSession();
        Constants.navigate(Constants.login);
      },
    },
  ] : [
    {
      title: "Dashboard",
      type: "link",
      icon: <HomeIcon />,
      route: Constants.dashboard,
    },
    {
      type: "divider",
    },
    {
      title: "SMS Templates",
      type: "link",
      icon: <SmsIcon />,
      route: Constants.smstable,
    },
    {
      title: "SMS Campaign",
      type: "link",
      icon: <MailIcon />,
      route: Constants.smsautomation,
    },
    {
      title: "Automation History",
      type: "link",
      icon: <HistoryIcon />,
      route: Constants.history,
    },
    {
      title: "Campaign History",
      type: "link",
      icon: <TimelineIcon />,
      route: Constants.campaignhistory,
    },
    {
      title: "Conversations",
      type: "link",
      icon: <QuestionAnswerIcon />,
      route: Constants.conversations,
      // condition: () => {
        // const isTwoWaySMSEnabled =
        //   user &&
        //   user.features &&
        //   user.features.indexOf("TWO_WAY_SMS_ENABLED") != -1;
        // return isTwoWaySMSEnabled;
      // },
    },
    {
      type: "divider",
    },
    {
      title: "Account",
      type: "button",
      icon: <AttachMoneyIcon />,
      onClick: () => {
        Constants.openCredits();
      },
    },
    {
      title: "Settings",
      type: "link",
      icon: <SettingsIcon />,
      route: Constants.settings,
    },
    {
      title: "Logout",
      type: "button",
      icon: <ExitToAppIcon />,
      onClick: () => {
        // logger.log("onClick");
        removeUserSession();
        Constants.navigate(Constants.login);
      },
    },
  ];

  const drawer = (
    <div>
      <List style={{ color: "white", fontWeight: "900" }}>
        {drawers1.map((item, index) => {
          if (item.condition && !item.condition()) {
            return null;
          }
          if (item.type === "divider") {
            return <Divider key={"dividerAt" + index} />;
          } else if (item.type === "button") {
            let className = classNameInactive;
            return (
              <Tooltip title={item.title}>
                <ListItem
                  button
                  key={item.title}
                  onClick={item.onClick}
                  className={className}
                >
                  <ListItemIcon style={{ color: "white" }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItem>
              </Tooltip>
            );
          } else if (item.type === "link") {
            let className = classNameInactive;
            if (item.route === selected) {
              className = classNameActive;
            }
            return (
              <Tooltip title={item.title}>
                <ListItem
                  button
                  key={item.route}
                  component={Link}
                  to={item.route}
                  className={className}
                  onClick={() => {
                    setSelected(item.route);
                  }}
                >
                  <ListItemIcon style={{ color: "white" }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItem>
              </Tooltip>
            );
          }
        })}
      </List>
    </div>
  );

  const getMainWidget = () => {
    let path = window.location.pathname
    let name = user.name ? user.name.split(' ')[0] : ''
    switch(path) {
      case (Constants.dashboard) :
        return <AccountSummary
            name={name}
          />

      case (Constants.smstable) :
        return <SMSTable
        name={name}
      />

      case (Constants.smsautomation) :
        return <SMSCampaignStepper
        name={name}
      />

      case (Constants.history) :
        return <HistoryTable
        name={name}
      />

      case (Constants.campaignhistory) :
        return <CampaignHistory
        name={name}
      />

      case (Constants.conversations) :
        return <Conversations
        name={name}
      />

      case (Constants.settings) :
        return <Settings
        name={name}
      />
      
    }
      
    
  }

  return (
    <>
    <div className={`${classes.root} min-height-800`}>
      {/* <BrowserRouter> */}
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          style={{ borderRight: "none" }}
        >
          <DrawerLogo open={open} />
          <div className={classes.toolbar}>
            <div style={{ position: "relative", left: "-10px" }}>
              <div style={{ position: "absolute" }}>
                <button
                  className={
                    open ? "c-header__toggle-btn" : "c-header__toggle-btn--show"
                  }
                  onClick={toggleDrawerOpenClose}
                  type="button"
                  style={{
                    position: "fixed",
                    border: "none",
                    background: "transparent",
                    outline: "none",
                    top: "60px",
                  }}
                >
                  <Icon name="hide-menu" />
                </button>
              </div>
            </div>
          </div>
          <Divider />
          {drawer}
          <Divider />
        </Drawer>
        <main className={classes.content}>

          {getMainWidget()}
        
        </main> 
    </div>
    <footer style={{color: "#fff", width: "100%", bottom: 0, padding: '10px', background: "#00AAF6", float: "left", position: "fixed"}}
    className="section-footer">
      <div style={{width: "50%", float: "left"}} className="footer-col">
        <a
        href={`mailto:?subject=Here's a Free Trial to ${store.get('user')?.partner?.whitelabel_name}.&body=Hey,%0D%0A %0D%0A Here's a link to a free trial of ${store.get('user')?.partner?.whitelabel_name}: ${store.get('user')?.partner?.whitelabel_link}, %0D%0A%0D%0AUnlock the potential of your sales and marketing strategies with ${store.get('user')?.partner?.whitelabel_name}, a robust CRM system designed to streamline your processes and drive growth.%0D%0A%0D%0A ${store.get('user')?.partner?.whitelabel_link} %0D%0A %0D%0ABest Regards,`} 
        style={{color: "#fff", paddingLeft: "70px"}}>
          {'        '}Share <b>{store.get('user')?.partner?.whitelabel_name}</b> with a friend! 💙
        </a>
      </div>
      <div style={{width: "50%", float: "left", textAlign: "right"}}  className="footer-col text-lt">
        <a href={`mailto:?subject=Here's a Free Trial to ${store.get('user')?.partner?.whitelabel_name} SMS Plugin.&body=Hey,%0D%0A %0D%0A Here's a link to that great ${store.get('user')?.partner?.whitelabel_name} SMS Plugin I told you about. ${store.get('user')?.partner?.partner_url}/ui/signup %0D%0A %0D%0ARegards,%0D%0A`} style={{color: "#fff"}}
        className="pl-70">
          Share <b>VYBRNT SMS</b> with a friend! 💙
        </a>
      </div>
    </footer>
  </>
  );
}

// export default withRouter(MiniDrawer);
export default MiniDrawer;