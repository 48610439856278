import React from "react";
import Switch from "@material-ui/core/Switch";
import logger from "./../../logger.js";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Constants from "./../../Utils/Constants";
import toastMaker from "./../../Common/toastmaker.js";
import axios from "axios";

import LoadingProgress from "./../../Common/LoadingProgress";
import { getHeaders, getUser } from "./../../Utils/Common";
import store from "./../../Common/storage";
import moment from "moment";

import Header from "./../../assets/components/header";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';

import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";

import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  ConversationHeader,
  Avatar,
  ExpansionPanel,
  Conversation,
  Sidebar,
  Search,
  ConversationList,
} from "@chatscope/chat-ui-kit-react";

import DateRangePicker from "@wojtekmaj/react-daterange-picker";

import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

import "./index.css";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "100%",
  },
});

const CHARACTER_LIMIT = 160;
const SORT_BY = ["DATE", "ALPHABETICAL"];
const DURATIONS = [
  {
    label: "1 Hour",
    value: "1_HOUR",
    getStartTs: () => {
      return getTsHoursInThePastInMillis(1);
    },
  },
  {
    label: "3 Hours",
    value: "3_HOURS",
    getStartTs: () => {
      return getTsHoursInThePastInMillis(3);
    },
  },
  {
    label: "24 Hours",
    value: "24_HOURS",
    getStartTs: () => {
      return getTsHoursInThePastInMillis(24);
    },
  },
  {
    label: "7 days",
    value: "7_DAYS",
    getStartTs: () => {
      return getTsHoursInThePastInMillis(7 * 24);
    },
  },
  {
    label: "30 Days",
    value: "30_Days",
    getStartTs: () => {
      return getTsHoursInThePastInMillis(30 * 24);
    },
  },
];

const getCurrentTsMillis = () => {
  return moment().valueOf();
};

const getTsHoursInThePastInMillis = (noOfHours) => {
  const numberOfMillis = noOfHours * 3600 * 1000;
  return getCurrentTsMillis() - numberOfMillis;
};

const getTsForParticularDateTimeInstant = (date) => {
  return moment(date).valueOf();
};

export default function StickyHeadTable(props) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

   // return focus to the button when we transitioned from !open -> open
   const prevOpen = React.useRef(open);
   React.useEffect(() => {
     if (prevOpen.current === true && open === false) {
       anchorRef.current.focus();
     }
 
     prevOpen.current = open;
   }, [open]);

  const classes = useStyles();
  const [serverData, setServerData] = React.useState(
    store.get("conversations")
  );
  const [messageInputValue, setMessageInputValue] = React.useState("");
  const [
    activeConversationNumber,
    setActiveConversationNumber,
  ] = React.useState(null);
  const [filter, setFilter] = React.useState("");
  const [sortBy, setSortBy] = React.useState(SORT_BY[0]);
  const [duration, setDuration] = React.useState(DURATIONS[4]);
  const [dateRange, onDateRangeChange] = React.useState(null);
  const [onlyReplies, setOnlyReplies] = React.useState(false);
  const [onlyUnread, setOnlyUnread] = React.useState(false);
  const [donotshowfilter, setDoNotShowFilter] = React.useState("");
  const [contactData, setContactData] = React.useState({});
  const [longcodes, setLongcodes] = React.useState([]);
  // const [longcode, setLongcode] = React.useState([]);
  

  const user = getUser();

  logger.log("user", user?.configs[store.get("c_id_index")]?.longcodes[0]);

  const isTwoWaySMSEnabled =
    user &&
    user?.configs &&
    user?.configs[store.get("c_id_index")] &&
    user?.configs[store.get("c_id_index")]?.longcodes &&
    user?.configs[store.get("c_id_index")]?.longcodes?.length > 0;

  let longcode = isTwoWaySMSEnabled && user?.configs[store.get("c_id_index")]?.longcodes[0]?.number;
  logger.log("virtualNumberId", user?.configs[store.get("c_id_index")]?.longcodes);

  const [virtualNumberId, setVirtualNumberId] = React.useState(longcode);

  React.useEffect(() => {
    let keepRunning = true;
    var recursive = () => {
      if (keepRunning) {
        refreshDataFromDuration()
        logger.log("Running recursive");
        setTimeout(recursive, 5000);
      }
    };
    
    setLongcodes(isTwoWaySMSEnabled && user?.configs[store.get("c_id_index")]?.longcodes)
    recursive();
    return () => {
      keepRunning = false;
    };
  }, [duration, virtualNumberId]);

  const refreshDataFromDuration = () => {
    // logger.log("duration", duration);
    const startTs = duration && duration.getStartTs && duration.getStartTs();
    const endTs = duration && duration.getEndTs && duration.getEndTs();
    return refreshData(startTs, endTs);
  };

  const refreshData = (startTs, endTs) => {
    
    const config = getHeaders();
    if (!virtualNumberId) {
      return;
    }
    
    let url = Constants.getApiPathWithConfigId(Constants.conversationsApiPath)?.replace('{v_number}', virtualNumberId);

    if (!config.params) {
      config.params = {};
    }
    if (startTs) {
      config.params.startTs = startTs;
    }
    if (endTs) {
      config.params.endTs = endTs;
    }
    axios.get(url, config)
          .then(response => setServerData(response.data.data))       
          .catch(error => console.error(error))
  };

  const sendReply = (innerHtml, textContent, innerText, nodes) => {
    const body = {
      number:selectedConversation.numberto || selectedConversation.numberfrom,
      ac_id: contactData?.id,
      virtual_number:virtualNumberId,
      message:innerText,
    };
    setMessageInputValue("");
    axios.post(
      Constants.getApiPathWithConfigId(Constants.sendtwowaysmsreplyApiPath),
        body,
        getHeaders()
      ).then(response => refreshDataFromDuration())
      .catch(error => console.error(error))
    
      // console.log('body', body)
    // const requestTwo = axios.post(
    //   Constants.sendtwowaysmsreplyApiPath,
    //   body,
    //   config
    // );
    // setMessageInputValue("");
    // return axios
    //   .all([requestTwo])
    //   .then(
    //     axios.spread((...responses) => {
    //       const responseHistory = responses[0];
    //       logger.log("responseHistory.data.data", responseHistory.data.data);
    //       setServerData(responseHistory.data.data);
    //       store.set("conversations", responseHistory.data.data);
    //     })
    //   )
    //   .catch((errors) => {
    //     console.error("error:", errors);
    //   });
  };

  const markConversationAsRead = (conversation) => {
    
    if (!conversation.hasUnreadMessages) {
      return;
    } 
    if (!virtualNumberId) {
      return;
    } 
    
    const { numberfrom, numberto } = conversation;
    const num = numberfrom || numberto
    if (!num) {
      return;
    }
    
    axios.put(Constants.getApiPathWithConfigId(Constants.markcoversationsreadApiPath)
          .replace('{virtual_number}', virtualNumberId).replace('{number}', num), 
          {},
          getHeaders())
         .then(response => refreshDataFromDuration())
         .catch(error => console.error(error))
   
  };

  const markAllConversationsAsRead = () => {
    if (!virtualNumberId) {
      return;
    }
    
    axios.put(Constants.getApiPathWithConfigId(Constants.markallcoversationsreadApiPath)
          .replace('{virtual_number}', virtualNumberId), {}, getHeaders())
          .then(response => refreshDataFromDuration())
          .catch(error => console.error(error))
                                           
  };

  if (!isTwoWaySMSEnabled) {
    return (
      <div>
        Conversations is not enabled, please contact support to enable this feature.
      </div>
    );
  }

  if (!serverData) {
    return <LoadingProgress />;
  }

  logger.log("serverData", serverData);
  const conversations = {};
  serverData &&
    serverData.forEach((item) => {
      const { type } = item;
      if (type === "outgoing") {
        const to = item.number, to_contact = item;
        var numberSanitised = getSanitisedNumber(to);
        if (!conversations[numberSanitised]) {
          conversations[numberSanitised] = {
            name: null,
            sms: [],
            numberto: to,
            numberSanitised,
          };
        }
        conversations[numberSanitised].sms.push(item);

        if (!conversations[numberSanitised].name && to_contact) {
          conversations[numberSanitised].name = '-'
        }
      }

      if (type === "incoming") {
        const from  = item.number;
        var numberSanitised = getSanitisedNumber(from);
        if (!conversations[numberSanitised]) {
          conversations[numberSanitised] = {
            name: null,
            active: false,
            sms: [],
            numberfrom: from,
            numberSanitised,
          };
        }
        conversations[numberSanitised].sms.push(item);
      }

      
      /*
    created_by: "afif.dadan@memorres.com"
    type: outgoing
created_on: "2021-06-22 18:46:00.623579"
from_virtual_number: "0426306174"
id: "009d1f28-9b5f-4350-87a2-fa81720accc4"
message: "Type your SMS message here.\nFor marketing message compliance, you can use the optout hyperlink at the end of this message as it is. (http://vc1.me/c/{code})"
status: "Active"
to: "+61450611664"
to_contact: "{\"firstName\":\"Tim\",\"lastName\":\"Preston\",\"phone\":\"+61450611664\",\"id\":\"2\",\"email\":\"tim@vybrnt.com\"}"
updated_on: "2021-06-22 18:46:00.623579"*/
    });

  logger.log("conversations", conversations);

  let filteredConvos = [];
  Object.keys(conversations).forEach((key) => {
    const convo = conversations[key];
    const filterLower = filter.toLowerCase();
    if (
      (convo.numberSanitised && convo.numberSanitised.indexOf(filter) != -1) ||
      (convo.name && convo.name.toLowerCase().indexOf(filterLower) != -1)
    ) {
      filteredConvos.push(convo);
    }
  });

  

  let atleastOneUnread = false;
  let selectedConversation = null;
  filteredConvos.forEach((convo) => {
    if (convo.numberSanitised === activeConversationNumber) {
      selectedConversation = convo;
    }

    //find latest message
    let currentLatest = null;
    let hasUnreadMessages = false;
    convo.sms &&
      convo.sms.forEach((item) => {
        // if (
        //   !currentLatest ||
        //   currentLatest.timestamp_millis < item.timestamp_millis
        // ) {
          currentLatest = item;
        // }
        if (item.type === "incoming" && item.read_flag == false) {
          hasUnreadMessages = true;
          atleastOneUnread = true;
        }
      });
      
    convo.latest = currentLatest;
    convo.hasUnreadMessages = hasUnreadMessages;

    //sort individual messages inside convo by timestamp
    convo.sms &&
      convo.sms.sort((a, b) => {
        const aDate = a.timestamp_millis || 0;
        const bDate = b.timestamp_millis || 0;
        //return a.attributes.OBJECTID - b.attributes.OBJECTID;
        if (aDate == bDate) return 0;
        if (aDate > bDate) return 1;
        if (aDate < bDate) return -1;
      });
  });

  if (sortBy === "DATE") {
    filteredConvos.sort((a, b) => {
      const aDate = (a.latest && a.latest.timestamp_millis) || 0;
      const bDate = (b.latest && b.latest.timestamp_millis) || 0;
      //return a.attributes.OBJECTID - b.attributes.OBJECTID;
      if (aDate == bDate) return 0;
      if (aDate > bDate) return -1;
      if (aDate < bDate) return 1;
    });
  } else if (sortBy === "ALPHABETICAL") {
    filteredConvos.sort((a, b) => {
      const aDate = (a.latest && a.latest.number) || "";
      const bDate = (b.latest && b.latest.number) || "";
      //return a.attributes.OBJECTID - b.attributes.OBJECTID;
      if (aDate == bDate) return 0;
      if (aDate < bDate) return -1;
      if (aDate > bDate) return 1;
    });
  }

  if (onlyReplies) {
    filteredConvos = filteredConvos.filter((convo) => {
      let isConvoWithReplies = false;
      for (var i = 0; i < convo.sms.length; i++) {
        const message = convo.sms[i];
        if (message.type === "incoming") {
          isConvoWithReplies = true;
          break;
        }
      }
      return isConvoWithReplies;
    });
  }

  if (onlyUnread) {
    filteredConvos = filteredConvos.filter((convo) => {
      return convo.hasUnreadMessages;
    });
  }

  if (donotshowfilter) {
    var donotshowfilterLower = donotshowfilter.toLowerCase();
    filteredConvos = filteredConvos.filter((convo) => {
      var showMe = true;
      convo.sms &&
        convo.sms.forEach((smsItem) => {
          if (
            smsItem.type === "incoming" &&
            smsItem.message.toLowerCase().indexOf(donotshowfilterLower) != -1
          ) {
            showMe = false;
          }
        });
      return showMe;
    });
  }

  return (
    <div>      
      <Header
        name={`Conversation Board${
          filteredConvos ? " (" + filteredConvos.length + ")" : ""
        }`}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          marginBottom: "10px",
        }}
        className="header-container-9547"
      >
        {atleastOneUnread && (
          <>
            <span style={{ marginBottom: "10px" }}>
              <span
                className="c-button c-button--secondary text-uppercase"
                style={{ fontSize: "10px" }}
                onClick={() => {
                  var confirmation = window.confirm(
                    "Are you sure you want to mark all conversations as read?"
                  );
                  if (confirmation) {
                    markAllConversationsAsRead();
                  }
                }}
              >
                Mark all as read
              </span>
            </span>
            <span>
              <span className="convo-header-4765">
                Show only conversations with unread messages:
              </span>
              <Switch
                checked={onlyUnread}
                onChange={() => {
                  setOnlyUnread(!onlyUnread);
                }}
                color="primary"
                name="onlyUnread"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </span>
          </>
        )}
        <span>
          <span className="convo-header-4765 fs-12">
            Show only conversations with replies:
          </span>
          <Switch
            checked={onlyReplies}
            onChange={() => {
              setOnlyReplies(!onlyReplies);
            }}
            color="primary"
            name="onlyReplies"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </span>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
        }}
        className="header-container-9547 ml-10"
      >
        {/* <span>
          <span className="convo-header-4765">Sort by:</span>
          <span
            onClick={() => {
              setSortBy("DATE");
            }}
            className={
              sortBy === "DATE"
                ? "convo-selections-4765--selected"
                : "convo-selections-4765"
            }
          >
            Date
          </span>
          <span
            onClick={() => {
              setSortBy("ALPHABETICAL");
            }}
            className={
              sortBy === "ALPHABETICAL"
                ? "convo-selections-4765--selected"
                : "convo-selections-4765"
            }
          >
            A-Z
          </span>
        </span> */}
        <span>
          <span className="convo-header-4765 ml-0">Past:</span>
          {DURATIONS.map((thisDuration) => {
            return (
              <span
                onClick={() => {
                  setDuration(thisDuration);
                  onDateRangeChange(null);
                }}
                className={
                  duration === thisDuration
                    ? "convo-selections-4765--selected"
                    : "convo-selections-4765"
                }
              >
                {thisDuration.label}
              </span>
            );
          })}
        </span>
        <span class="mt-2">
          <DateRangePicker
            onChange={(dates) => {
              logger.log("dates ert", dates);
              if (!dates) {
                setDuration(DURATIONS[4]);
              } else {
                const customDuration = {
                  getStartTs: () => {
                    return getTsForParticularDateTimeInstant(dates[0]);
                  },
                  getEndTs: () => {
                    return getTsForParticularDateTimeInstant(dates[1]);
                  },
                };
                setDuration(customDuration);
              }
              onDateRangeChange(dates);
            }}
            calendarIcon={
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.6562 1.5625H16.7188V0H15.1562V1.5625H4.84375V0H3.28125V1.5625H2.34375C1.05141 1.5625 0 2.61391 0 3.90625V17.6562C0 18.9486 1.05141 20 2.34375 20H17.6562C18.9486 20 20 18.9486 20 17.6562V3.90625C20 2.61391 18.9486 1.5625 17.6562 1.5625ZM18.4375 17.6562C18.4375 18.087 18.087 18.4375 17.6562 18.4375H2.34375C1.91297 18.4375 1.5625 18.087 1.5625 17.6562V7.34375H18.4375V17.6562ZM18.4375 5.78125H1.5625V3.90625C1.5625 3.47547 1.91297 3.125 2.34375 3.125H3.28125V4.6875H4.84375V3.125H15.1562V4.6875H16.7188V3.125H17.6562C18.087 3.125 18.4375 3.47547 18.4375 3.90625V5.78125Z"
                  fill="#122643"
                />
                <path
                  d="M4.53125 8.98438H2.96875V10.5469H4.53125V8.98438Z"
                  fill="#122643"
                />
                <path
                  d="M7.65625 8.98438H6.09375V10.5469H7.65625V8.98438Z"
                  fill="#122643"
                />
                <path
                  d="M10.7812 8.98438H9.21875V10.5469H10.7812V8.98438Z"
                  fill="#122643"
                />
                <path
                  d="M13.9062 8.98438H12.3438V10.5469H13.9062V8.98438Z"
                  fill="#122643"
                />
                <path
                  d="M17.0312 8.98438H15.4688V10.5469H17.0312V8.98438Z"
                  fill="#122643"
                />
                <path
                  d="M4.53125 12.1094H2.96875V13.6719H4.53125V12.1094Z"
                  fill="#122643"
                />
                <path
                  d="M7.65625 12.1094H6.09375V13.6719H7.65625V12.1094Z"
                  fill="#122643"
                />
                <path
                  d="M10.7812 12.1094H9.21875V13.6719H10.7812V12.1094Z"
                  fill="#122643"
                />
                <path
                  d="M13.9062 12.1094H12.3438V13.6719H13.9062V12.1094Z"
                  fill="#122643"
                />
                <path
                  d="M4.53125 15.2344H2.96875V16.7969H4.53125V15.2344Z"
                  fill="#122643"
                />
                <path
                  d="M7.65625 15.2344H6.09375V16.7969H7.65625V15.2344Z"
                  fill="#122643"
                />
                <path
                  d="M10.7812 15.2344H9.21875V16.7969H10.7812V15.2344Z"
                  fill="#122643"
                />
                <path
                  d="M13.9062 15.2344H12.3438V16.7969H13.9062V15.2344Z"
                  fill="#122643"
                />
                <path
                  d="M17.0312 12.1094H15.4688V13.6719H17.0312V12.1094Z"
                  fill="#122643"
                />
              </svg>
            }
            value={dateRange}
          />
        </span>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
        }}
        className="header-container-9547 ml-10 mt-3"
      >
        <span style={{ marginRight: "10px" }} className="mb-2">
          Do not show conversations with replies containing following text:
        </span>
        <input
          value={donotshowfilter}
          onChange={(e) => {
            setDoNotShowFilter(e.target.value);
          }}
        />
      </div>
      <br />
      <div className="conversation-section">
        <Paper className={classes.root}>
          <div
            style={{
              height: "600px",
              position: "relative",
            }}
          >              
            <MainContainer responsive>
              <Sidebar position="left" scrollable={false} id="sidebar-4874">
                <Search
                  placeholder="Search..."
                  value={filter}
                  onChange={setFilter}
                  onClearClick={() => {
                    setFilter("");
                  }}
                />
                <ConversationList>
                  {filteredConvos.map((conversation) => {
                    const numberSanitised = conversation.numberSanitised;
                    const latest = conversation.latest;
                    
                    const lastActivityTime =
                      latest && latest.created_at
                        ? new Date(latest.created_at).toLocaleString()
                        : "...";
                    const active =
                      selectedConversation &&
                      numberSanitised === selectedConversation.numberSanitised;
                    let latestMessage = "...";
                    if (latest && latest.message) {
                      latestMessage = latest.message;
                      if (latestMessage.length > 30) {
                        latestMessage = latestMessage.substring(0, 30) + "...";
                      }
                    }
                    return (
                      <Conversation
                        //name={conversation.name}
                        onClick={() => { 
                          setContactData({})
                          if(conversation.latest.ac_id == 0) {
                            axios.get(Constants.getApiPathWithConfigId(Constants.getACContactDetailsByMobile).replace('{number}', numberSanitised), getHeaders())
                              .then(response => setContactData(response.data.data))
                              .catch(errors => console.error(errors))
                          } else {
                            axios.get(Constants.getApiPathWithConfigId(Constants.getACContactDetailsById).replace('{ac_id}', conversation.latest.ac_id), getHeaders())
                              .then(response => setContactData(response.data.data))
                              .catch(errors => console.error(errors))
                          }
                          setActiveConversationNumber(numberSanitised);
                          var el = document.getElementById("chatcontainer-4874");
                          el.style = null;
                          var e2 = document.getElementById("sidebar-4874");
                          e2.style = null;
                          if (conversation.hasUnreadMessages) { 
                            markConversationAsRead(conversation);
                          }
                        }}
                        active={active}
                      >
                        <Conversation.Content
                          style={{
                            fontFamily: "Open Sans",
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: "14px",
                            lineHeight: "150%",
                            display: "flex",
                            alignItems: "left",
                            color: "#272833",
                            fontWeight: conversation.hasUnreadMessages
                              ? "bold"
                              : null,
                          }}
                        >
                          <div>
                            {conversation.name}
                            <span style={{ float: "right" }}>
                              {lastActivityTime}
                            </span>
                          </div>
                          <div>
                            {conversation.numberto || conversation.numberfrom}
                          </div>
                          <div>{latestMessage}</div>
                        </Conversation.Content>
                      </Conversation>
                    );
                  })}
                </ConversationList>
              </Sidebar>

              <ChatContainer id="chatcontainer-4874">
                <ConversationHeader>                
                  <ConversationHeader.Back
                    onClick={() => {
                      var el = document.getElementById("chatcontainer-4874");
                      el.style.display = "none";
                      var e2 = document.getElementById("sidebar-4874");
                      e2.style.display = "flex";
                      e2.style["flex-basis"] = "auto";
                      e2.style.width = "100%";
                      e2.style["max-width"] = "100%";
                    }}
                  />
                  
                  <ConversationHeader.Content> 
                  <div>
                    <div className="col-left">
                      <p className="user-name">
                        {(contactData?.fname) || "..."}
                      </p>
                      <p className="reference-no">
                        {(selectedConversation && selectedConversation.numberSanitised) || "..."}
                      </p>
                    </div>
                    <div className="col-right">
                    <Stack>
                      <div className="icon-btn">
                        <select onChange={e => setVirtualNumberId(e.target.value)}>
                          {
                            longcodes.map(l => (
                              <option value={l.number}
                                onClick={e => {alert(l.number)}}
                              >{l.number}</option>)
                            )
                          }
                        </select>
                        <IconButton
                          ref={anchorRef}
                          id="composition-button"
                          aria-controls={open ? 'composition-menu' : undefined}
                          aria-expanded={open ? 'true' : undefined}
                          aria-haspopup="true"
                          onClick={handleToggle}
                        >
                        <MoreVertIcon />
                        </IconButton>
                        <Popper
                          open={open}
                          anchorEl={anchorRef.current}
                          role={undefined}
                          placement="bottom-start"
                          transition
                          disablePortal
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{
                                transformOrigin:
                                  placement === 'bottom-start' ? 'left top' : 'left bottom',
                              }}
                            >
                              <Paper className="option">
                              {selectedConversation && (
                                <ClickAwayListener onClickAway={handleClose}>
                                  <MenuList
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    onKeyDown={handleListKeyDown}
                                  >
                                    <MenuItem onClick={handleClose}>                                
                                      <p>
                                        {contactData?.id && <a
                                          href={
                                            Constants.getAcContactUrl(contactData?.id)
                                          }
                                          style={{ textDecoration: "underline" }}
                                          target="_blank"
                                        >
                                          View Contact
                                        </a>}
                                      </p>
                                    </MenuItem>
                                    <MenuItem onClick={handleClose}>
                                    <p>
                                      {contactData?.id && <a
                                        href={`/u/${store.get('c_id')}/${contactData?.id}`}
                                        style={{ textDecoration: "underline" }}
                                        target="_blank"
                                      >
                                        Optout
                                      </a>}
                                    </p>
                                    </MenuItem>
                                    {/* <p>
                                      <a
                                        href={""}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          toastMaker.makeToast("Coming soon!...", {
                                            variant: "info",
                                          });
                                        }}
                                        style={{ textDecoration: "underline" }}
                                      >
                                        Block User
                                      </a>
                                    </p> 
                                    <p>
                                      <a
                                        href={""}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          toastMaker.makeToast("Coming soon!...", {
                                            variant: "info",
                                          });
                                        }}
                                        style={{ textDecoration: "underline" }}
                                      >
                                        Delete Conversation
                                      </a>
                                    </p> */}                        
                                  </MenuList>
                                </ClickAwayListener>
                                )}
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      </div>
                    </Stack>
                    </div>
                  </div>
                  
                  </ConversationHeader.Content>                
                </ConversationHeader>
                <MessageList>
                  {selectedConversation &&
                    selectedConversation.sms &&
                    selectedConversation.sms.map((message) => {
                      const { type, timestamp_millis } = message;
                      return (
                        <Message
                          model={{
                            message: message.message,
                            sentTime: "15 mins ago",
                            //sender: "Zoe",
                            direction: type,
                            position: "single",
                          }}
                        >
                          <Message.Footer
                            sentTime={new Date(message.created_at).toLocaleString()}
                          />
                        </Message>
                      );
                    })}
                </MessageList>
                <MessageInput
                  placeholder={
                    selectedConversation
                      ? "Write a message"
                      : "Select a conversation..."
                  }
                  value={messageInputValue}
                  onChange={(val) => setMessageInputValue(val)}
                  onSend={sendReply}
                  disabled={!selectedConversation}
                  attachButton={false}
                />
              </ChatContainer>

              
            </MainContainer>
          </div>
        </Paper>
      </div>
    </div>
  );
}

function getSanitisedNumber(number) {
  // number = number.replace(/[^\d]/g, "").replace(/^.*(\d{10})$/, "$1");
  var final = number;
  // var amountToTruncate = v.length - 9;
  // if (amountToTruncate > 0) {
  //   final = v.slice(amountToTruncate);
  // }
  return final;
}
