import React from "react";
import FeatureItem from "./FeatureItem";

import "./index.css";

export default function({data}) {
  return (
    <div className="c_layout-feature">
      <div className="c_layout-feature__row">
        <FeatureItem
          heading="Use a Custom Sender ID!"
          description={`Send your SMS campaigns to an ${data?.whitelabel_name} list with a custom sender ID, up to 11 characters.`}
        />
        <FeatureItem
          heading="Send Bulk SMS!"
          description={`Easily send bulk SMS to contacts standalone or through ${data?.whitelabel_name} lists!`}
        />
      </div>
      <div className="c_layout-feature__row">
        <FeatureItem
          heading="Automate SMS!"
          description={`Drop an SMS into your ${data?.whitelabel_name} Nurture Sequence with a Webhook. Save SMS Templates`}
        />
        <FeatureItem
          heading="Track leads!"
          description={`Track information back to ${data?.whitelabel_name} through Tags!!`}
        />
      </div>
    </div>
  );
}
