import React, { useState, useEffect } from "react";
import logger from "./../../logger";
import axios from "axios";
import { Link, redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

import { setUserSession, setUserTokens } from "../.././Utils/Common";
import Constants from "./../../Utils/Constants"
import { hashString } from "./../../Utils/Common";

import HomeLogo from "./../../Common/HomeLogo";
import HomeFooter from "./../../Common/HomeFooter";
import FeatureGrid from "./../../Common/FeatureGrid";

import "./index.scoped.css";

function Login(props) {
  const username = useFormInput("");
  const password = useFormInput("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState()

  useEffect(() => {
    let url = window.location.href.split('/')[2]
    axios.get(Constants.partnersApiPath + `?domain=${url}`)
    .then(r => setContent(r.data.data))
    .catch(e => console.error(e))
  }, [])

  // handle button click of login form
  const handleLogin = () => {
    // logger.log("handlelogin", props);
    setError(null);
    setLoading(true);

    Constants.api.login(username.value, password.value)
                  .then(response => {
                    setLoading(false);
                    // setUserTokens()
                    axios.get(Constants.userApiPath, {
                      headers: { Authorization: `Bearer ${response.data.access_token}` },
                    })
                              .then(r => {
                                let data = r.data.data
                                setUserSession(response.data.access_token, response.data.refresh_token, response.data.expires_in, data, props.history);
                                Constants.navigate(Constants.dashboard)
                              })
                              .catch(e => console.log(e, 'e'))
                  })
                  .catch(error => {
                    setLoading(false);
                    error &&
                    error.response &&
                    error.response.data &&
                    error.response.data.message ?
                            setError(error.response.data.message) :
                            setError("Please check your username and password");
                  })
    // axios
    //   .post(Constants.loginApiPath, {
    //     username: username.value,
    //     password: hashString(password.value),
    //   })
    //   .then((response) => {
    //     setLoading(false);
    //     setUserSession(response.data.data, props.history);
    //     Constants.navigate(Constants.dashboard)
    //   })
    //   .catch((error) => {
    //     logger.log("error", error);
    //     setLoading(false);
    //     if (
    //       error &&
    //       error.response &&
    //       error.response.data &&
    //       error.response.data.message
    //     ) {
    //       setError(error.response.data.message);
    //     } else {
    //       setError("Something went wrong. Please try again later.");
    //     }
    //   });
  };

  return (
    <div style={{}}>
      <div>
        <Grid container spacing={0}>
          <FeatureGrid data={content} />
          <Grid item md={6} xs={12} className="formcontent">
            <div className="loginMain">
              <HomeLogo data={content} />
              <div className="login-wrap">
                <h3 className="login-wrap_heading mb-30">login</h3>
                <div className="form-group" style={{ width: "100%" }}>
                  <input
                    placeholder="Enter Email"
                    className="login-textbx"
                    {...username}
                    type="text"
                    id="email"
                    name="email"
                    value={username.value}
                  />
                </div>
                <div className="form-group" style={{ width: "100%" }}>
                  <input
                    placeholder="Enter Password"
                    className="login-textbx"
                    {...password}
                    type="password"
                    value={password.value}
                    id="password"
                    name="password"
                    onKeyPress={(event) => {
                      if (event.key == "Enter") {
                        handleLogin();
                      }
                    }}
                  />
                </div>
                <div>
                  {error && (
                    <>
                      <small style={{ color: "red" }}>{error}</small>
                      <br />
                    </>
                  )}
                </div>
                <div className="action-link">
                  <Link 
                    className="forgot-link"
                    to={Constants.forgotpassword}
                    style={{ textDecoration: "underline" }}
                  >
                    Forgot Password?
                  </Link>

                  <Link 
                    className="forgot-link"
                    to={Constants.signup}
                    style={{ textDecoration: "underline" }}
                  >
                    Don't have an account? Create one.
                  </Link>

                  <input
                    className="login-btn c-button"
                    type="button"
                    value="SIGN IN"
                    onClick={handleLogin}
                    disabled={loading}
                  />
                </div>
              </div>
              <HomeFooter />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange,
  };
};

export default Login;
