import React, { useState, useEffect } from "react";
import logger from "./../../logger";
import axios from "axios";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

import { setUserSession } from "../.././Utils/Common";
import Constants from "./../../Utils/Constants";
import { hashString } from "./../../Utils/Common";
import toastMaker from "./../../Common/toastmaker";

import HomeLogo from "./../../Common/HomeLogo";
import HomeFooter from "./../../Common/HomeFooter";
import FeatureGrid from "./../../Common/FeatureGrid";
// import Link from "@material-ui/core/Link";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import "./index.scoped.css";

function Signup(props) {
  const firstname             = useFormInput("");
  const lastname              = useFormInput("");
  const account               = useFormInput("");
  const contactno             = useFormInput("");
  const country               = useFormInput("");
  const postcode              = useFormInput("");
  const username              = useFormInput("");
  const password              = useFormInput("");
  const reenterpassword       = useFormInput("");
  const [error, setError]     = useState(null);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState()

  useEffect(() => {
    let url = window.location.href.split('/')[2]
    axios.get(Constants.partnersApiPath + `?domain=${url}`)
    .then(r => setContent(r.data.data))
    .catch(e => console.error(e))
  }, [])

  // handle button click of login form
  const handleSignup = () => {
    if (password.value !== reenterpassword.value) {
      //throw exception that passwords dont match
      toastMaker.makeToast("Password fields do not match. Please re enter.", {
        variant: "error",
      });
      return;
    }

    const requiredFields = [
      firstname,
      lastname,
      contactno,
      country,
      postcode,
      username,
      password,
    ];
    for (let i = 0; i < requiredFields.length; i++) {
      const field = requiredFields[i];
      if (!field || !field.value || field.value.length == 0) {
        toastMaker.makeToast("Please enter all required fields.", {
          variant: "error",
        });
        return;
      }
    }
    if (!state.checkedB) {
      toastMaker.makeToast("Please accept terms and conditions to proceed.", {
        variant: "error",
      });
      return;
    }
    logger.log("in here");
    setError(null);
    setLoading(true);
    axios
      .post(Constants.signupApiPath, {
        name: firstname.value + ' ' + lastname.value,
        account: account.value,
        mobile: contactno.value,
        country: country.value,
        zipcode: postcode.value,
        email: username.value,
        password: password.value,
        partner_id: content.id
      })
      .then((response) => {
        let userid = response.data.data.id
        axios.post(`${Constants.useracaccountApiPath}${userid}`)
             .then(response => {
                 axios.post(`${Constants.useraccontactApiPath}${userid}`)
                      .then(response => {
                          setLoading(false);
                          toastMaker.makeToast("Account created successfully, please login.", {
                            variant: "success",
                          });
                          // setUserSession(response.data.data);
                          Constants.navigate(Constants.login);
                      })
                      .catch((error) => {
                        logger.log("error", error.response);
                        setLoading(false);
                        if (
                          error &&
                          error.response &&
                          error.response.data &&
                          error.response.data.message
                        ) {
                          setError(error.response.data.message);
                        } else {
                          setError("Something went wrong. Please try again later.");
                        }
                      })
             })
             .catch((error) => {
              logger.log("error", error.response);
              setLoading(false);
              if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.message
              ) {
                setError(error.response.data.message);
              } else {
                setError("Something went wrong. Please try again later.");
              }
            })
      })
      .catch((error) => {
        logger.log("error", error.response);
        setLoading(false);
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setError(error.response.data.message);
        } else {
          setError("Something went wrong. Please try again later.");
        }
      });
  };

  const [state, setState] = React.useState({
    checkedB: false,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const TermsAndConditions = (props) => {
    return (
        <span className="terms" style={{color: '#fff'}}>
          *By using the VYBRNT SMS platform, you consent to our legal terms and
          policies.{" "}
          <a
            id="info_link"
            href="#"
            download
            onClick={(event) => {
              event.preventDefault();
              const filesForDownload = [];
              filesForDownload.push({
                path:
                  "https://vybrntsms-static.s3-ap-southeast-2.amazonaws.com/Anti_Spam_Policy.pdf",
                name: "Anti Spam Policy.pdf",
              });
              filesForDownload.push({
                path:
                  "https://vybrntsms-static.s3-ap-southeast-2.amazonaws.com/Privacy_Policy.pdf",
                name: "Privacy Policy.pdf",
              });
              var temporaryDownloadLink = document.createElement("a");
              temporaryDownloadLink.style.display = "none";

              document.body.appendChild(temporaryDownloadLink);
              for (var n = 0; n < filesForDownload.length; n++) {
                var download = filesForDownload[n];
                temporaryDownloadLink.setAttribute("href", download.path);
                temporaryDownloadLink.setAttribute("download", download.name);

                temporaryDownloadLink.click();
              }
              document.body.removeChild(temporaryDownloadLink);
            }}
          >
            See here for more information
          </a>
        </span>
    );
  };

  return (
    <div style={{}}>
      <div>
        <Grid container spacing={0}>
          <FeatureGrid data={content} />

          <Grid item md={6} xs={12} className="formcontent">
            <div className="loginMain">
              <HomeLogo data={content} />

              <div className="login-wrap">
                <h3 className="login-wrap_heading mb-30">Sign Up</h3>

                <div className="form">
                  <div className="form-group">
                    <input
                      placeholder="First Name*"
                      className="login-textbx"
                      type="text"
                      id="firstname"
                      label="First Name"
                      {...firstname}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      placeholder="Last Name*"
                      className="login-textbx"
                      type="text"
                      id="lastname"
                      label="Last Name"
                      {...lastname}
                    />
                  </div>

                  <div className="form-group">
                    <input
                      placeholder="Account (Company)*"
                      className="login-textbx"
                      id="account"
                      label="Account(Company)"
                      {...account}
                    />
                  </div>

                  <div className="form-group">
                    <input
                      placeholder="Phone Number*"
                      className="login-textbx"
                      type="text"
                      id="contactno"
                      label="Phone Number"
                      {...contactno}
                    />
                  </div>

                  <div className="form-group">
                    <input
                      placeholder="Country*"
                      className="login-textbx"
                      type="text"
                      id="country"
                      label="Country"
                      {...country}
                    />
                  </div>
                </div>

                <div className="form">
                  <div className="form-group">
                    <input
                      placeholder="Postcode/Zipcode*"
                      className="login-textbx"
                      type="text"
                      id="postcode"
                      label="Postcode/Zipcode"
                      {...postcode}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      placeholder="Email*"
                      className="login-textbx"
                      type="text"
                      id="email"
                      label="Email"
                      {...username}
                    />
                  </div>

                  <div className="form-group">
                    <input
                      placeholder="Password*"
                      className="login-textbx"
                      type="password"
                      id="password"
                      label="Password"
                      {...password}
                    />
                  </div>

                  <div className="form-group">
                    <input
                      placeholder="Reenter Password*"
                      className="login-textbx"
                      type="password"
                      id="reenterpassword"
                      label="Reenter Password"
                      {...reenterpassword}
                    />
                  </div>
                </div>

                <div>
                  {error && (
                    <>
                      <small style={{ color: "red" }}>{error}</small>
                      <br />
                    </>
                  )}
                </div>
              </div>

              <div>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedB}
                        onChange={handleChange}
                        name="checkedB"
                        style={{ color: "white" }}
                      />
                    }
                    label={<TermsAndConditions/>}
                  />
                </div>

                <div className="form">
                  <Link
                    className="forgot-link terms"
                    style={{ textDecoration: "underline" }}
                    to={Constants.login}>
                      Already have an account? Sign in.
                  </Link>

                </div>

                <div className="form">
                  <input
                    className="login-btn c-button pull-right"
                    type="button"
                    value="SIGN UP"
                    onClick={handleSignup}
                    disabled={loading}
                  />
                </div>
              </div>

              <HomeFooter />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange,
  };
};

export default Signup;
