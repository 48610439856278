import React from "react";
import axios from "axios";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CallIcon from "@material-ui/icons/Call";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";

import Constants from "./../../Utils/Constants";
import LoadingProgress from "./../../Common/LoadingProgress";
import { getToken, getHeaders } from "./../../Utils/Common";
import store from "./../../Common//storage";
import Header from "./../../assets/components/header";

import Pagination from '@material-ui/lab/Pagination';

import "./../../index.css";

const columns = [
  { id: "sms", label: "Sent Message", minWidth: 170 },
  { id: "sender_id", label: "Sender Id", minWidth: 170 },
  {
    id: "name",
    label: `${store?.get('user')?.partner?.whitelabel_name} Contact`,
    minWidth: 170,
    align: "left",
  },
  {
    id: "created_at",
    label: "Send Time",
    minWidth: 170,
    align: "center",
  },
];

const StyledTableCell = withStyles((theme) => ({
  body: {
    fontSize: 16,
    fontWeight: 600,
    padding: 15,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "rgba(251, 220, 119, 0.28)",
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "100%",
  },
});

export default function StickyHeadTable(props) {
  const classes = useStyles();
  const [serverData, setServerData] = React.useState(store.get("history"));
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(0);
  let interval = null;

  const handleChange = (event, value) => {
    setPage(value);
  };

  React.useEffect(() => {
    refreshData();
    interval = setInterval(() => {
      refreshData();
    }, 6000);
    return () => {
      clearInterval(interval);
    };
  }, [page]);

  const refreshData = () => {
    axios.get(Constants.getApiPathWithConfigId(Constants.historyApiPath) + `?per_page=20&page=${page}&order_by=id&order=desc`, getHeaders())
                      .then(response => {
                        // setServerData([])
                        setPageCount(response.data.meta.last_page)
                        setServerData(response.data.data)
                      }).catch(error => console.error(error))
  };

  if (!serverData) {
    return <LoadingProgress />;
  }

  return (
    <div className="panal-viewport middle-container"> 
      <Header name={"SMS Automation History"} />
      <Paper className={`${classes.root} pb-2`}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow id="table-head_rw">
                <StyledTableCell className="table-hd">
                  Sent Message
                </StyledTableCell>
                <StyledTableCell className="table-hd">
                  Sender ID
                </StyledTableCell>
                <StyledTableCell className="table-hd">
                  {store?.get('user')?.partner?.whitelabel_name} Contact
                </StyledTableCell>
                <StyledTableCell className="table-hd">
                  Send Date,Time
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {serverData?.map((row) => {
                const rowId = row.id;
                // console.log(row, 'row')
                return (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={rowId}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      if (column.id === "sms") {
                        return (
                          <StyledTableCell
                            component="th"
                            scope="row"
                            key={column.id}
                          >
                            <div className="speech-bubble">
                              <span>{value}</span>
                            </div>
                          </StyledTableCell>
                        );
                      } else if (column.id === "name") {
                        return (
                          <StyledTableCell>
                            <div className="template-info">
                              <div className="template-info__item">
                                <PermIdentityIcon
                                  fontSize="small"
                                  style={{ fontSize: "0.9rem" }}
                                />
                                <span
                                  style={{
                                    fontWeight: "700",
                                    marginLeft: "5px",
                                    color: "#000",
                                  }}
                                >
                                  {" "}
                                  {value}{" "}
                                </span>
                              </div>
                            </div>
                            <a href={Constants.getAcContactUrl(row.ac_id)} target="_blank">
                              Contact Details
                            </a>
                          </StyledTableCell>
                        );
                      } else if (column.id === "created_at") {
                        return (
                          <StyledTableCell>
                            <div className="send-time">
                              <span>
                                {new Date(value).toLocaleString()}
                              </span>
                            </div>
                          </StyledTableCell>
                        );
                      } else {
                        return (
                          <StyledTableCell className="text-blue">
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </StyledTableCell>
                        );
                      }
                    })}
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {pageCount > 0 && <div className={`${classes.root} mt-3 mb-3 d-flex justify-content-end`}>
                  <Pagination 
                    count={pageCount} 
                    page={page} 
                    onChange={handleChange} 
                    variant="outlined" 
                    shape="rounded"
                    />
                </div>}
      </Paper>
    </div>
  );
}
