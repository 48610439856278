import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Constants from "./../../Utils/Constants";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import CampaignSMSDetailedHistory from "./CampaignSMSDetailedHistory";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

import LoadingProgress from "./../../Common/LoadingProgress";
import { getToken, getHeaders } from "./../../Utils/Common";
import store from "./../../Common/storage";

import Header from "./../../assets/components/header";

import Pagination from '@material-ui/lab/Pagination';

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "rgba(251, 220, 119, 0.28)",
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  body: {
    fontSize: 16,
    fontWeight: 600,
    padding: 15,
  },
}))(TableCell);

const columns = [
  {
    id: "name",
    label: `Campaign Name [${store?.get('user')?.partner?.whitelabel_name} Tag]`,
    minWidth: 70,
  },
  { id: "description", label: "Description", minWidth: 170 },
  { id: "status", label: "Status", minWidth: 50 },
  {
    id: "created_at",
    label: "Send Time",
    minWidth: 170,
    align: "center",
  },
];

// const status = [
//     /* 0 =>  */ 'Created',                 // Created and ready for configuration                | Step 1 | User 
//     /* 1 =>  */ 'Executed',                // Executed AKA Archived                              | Step 7 | System
//     /* 2 =>  */ 'Pending',                 // Configured and pending execution                   | Step 4 | System
//     /* 3 =>  */ 'In Progress',              // Execution in progress                              | Step 6 | System
//     /* 4 =>  */ 'Failed',                  // Error occured while execution                      | Step 7 | System
//     /* 5 =>  */ 'PartiallyCompleted',      // Partially completed and stopped by user (Pause)    | Step 8 | System
//     /* 6 =>  */ 'Scheduled',               // Configured and waiting for execution time          | Step 2 | User
//     /* 7 =>  */ 'Fetching',                // Fetching contacts from AC                          | Step 3 | System
//     /* 8 =>  */ 'Pushing',                 // Pushing contacts to SP                             | Step 5 | System
//     /* 9 =>  */ 'Cancelled'                // Cancelled or deleted campaign                      | Step 9 | User
// ]

const status = [
  /* 0 =>  */ 'In Progress',                 // Created and ready for configuration                | Step 1 | User 
  /* 1 =>  */ 'Completed',                // Executed AKA Archived                              | Step 7 | System
  /* 2 =>  */ 'In Progress',                 // Configured and pending execution                   | Step 4 | System
  /* 3 =>  */ 'In Progress',              // Execution in progress                              | Step 6 | System
  /* 4 =>  */ 'Completed',                  // Error occured while execution                      | Step 7 | System
  /* 5 =>  */ 'In Progress',      // Partially completed and stopped by user (Pause)    | Step 8 | System
  /* 6 =>  */ 'Scheduled',               // Configured and waiting for execution time          | Step 2 | User
  /* 7 =>  */ 'In Progress',                // Fetching contacts from AC                          | Step 3 | System
  /* 8 =>  */ 'In Progress',                 // Pushing contacts to SP                             | Step 5 | System
  /* 9 =>  */ 'Cancelled'                // Cancelled or deleted campaign                      | Step 9 | User
]

const log_status = [
  /* 0 =>  */ 'In Progress',                 // Created and ready
  /* 1 =>  */ 'Success',                     // sms sent successfully
  /* 2 =>  */ 'Failed',                      // Failed sending sms
  /* 3 =>  */ 'In Progress',                 // Waiting - in progress as waiting for response from service provider
  /* 4 =>  */ 'In Progress',                 // Queued - in prgress to be pused to service provider
]

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "100%",
  },
});

const CHARACTER_LIMIT = 160;

export default function StickyHeadTable(props) {
  const classes = useStyles();
  const [serverData, setServerData] = React.useState(
    store.get("campaignhistory")
  );
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(0);
  let interval = null;

  const handleChange = (event, value) => {
    setPage(value);
  };

  React.useEffect(() => {
    refreshData();
    
    // interval = setInterval(() => {
    //   refreshData();
    // }, 10000);
    // return () => {
    //   clearInterval(interval);
    // };
  }, [page]);

  // const getHeaders = () => {
  //   return {
  //     headers: { Authorization: `Bearer ${getToken()}` },
  //   };
  // };

  const refreshData = () => {
    axios.get(Constants.getApiPathWithConfigId(Constants.campaignhistoryApiPath) + `?per_page=20&page=${page}&order_by=id&order=desc`, getHeaders())
                            .then(response => {
                              // setServerData([])
                              setServerData(response.data.data)
                              setPageCount(response.data.meta.last_page)
                            })
                            .catch(error => console.error(error))
  //   return axios
  //     .all([requestTwo])
  //     .then(
  //       axios.spread((...responses) => {
  //         const responseHistory = responses[0];
  //         setServerData(responseHistory.data.data);
  //         store.set("campaignhistory", responseHistory.data.data);
  //       })
  //     )
  //     .catch((errors) => {
  //       console.error("error:", errors);
  //     });
  };

  if (!serverData) {
    return <LoadingProgress />;
  }

  const CampaignHistoryRow = (props) => {
    const row = props.row;
    const rowId = row.id;
    const [show, setShow] = React.useState(false);
    const [serverData, setServerData] = React.useState();

    const getData = () => {
      const config = getHeaders();
      const requestTwo = axios.get(Constants.campaignhistorydetailApiPath, {
        ...config,
        params: {
          campaignId: row.id,
        },
      });
      return axios
        .all([requestTwo])
        .then(
          axios.spread((...responses) => {
            const responseHistory = responses[0];
            setServerData(responseHistory.data.data.campaign);
          })
        )
        .catch((errors) => {
          console.error("error:", errors);
        });
    };

    return (
      <React.Fragment>
        <StyledTableRow
          hover
          role="checkbox"
          tabIndex={-1}
          key={rowId}
          onClick={() => {
            const opposite = !show;
            setShow(opposite);
            if (opposite === true) {
              // getData();
              setServerData(row)
            }
          }}
        >
          {columns.map((column) => {
            const value = row[column.id];
            if (column.id === "created_at") {
              return (
                <StyledTableCell>
                  <div className="send-time">
                    <span>{new Date(value).toLocaleString()}</span>
                  </div>
                </StyledTableCell>
              );
            } else if(column.id === "status") {
              return (
                <StyledTableCell>
                  <div className="send-time">
                    <span>
                      {
                        status[value]
                      }
                    </span>
                  </div>
                </StyledTableCell>
                )
            } else {
              return (
                <StyledTableCell>
                  <div className="send-time">
                    <span>
                      {column.format && typeof value === "number"
                        ? column.format(value)
                        : value == 'null' ? '-' : value}
                    </span>
                  </div>
                </StyledTableCell>
              );
            }
          })}
        </StyledTableRow>
        {show && serverData && (
          <ExpansionPanelDetails style={{ display: "block" }}>
            <div className="row">
              <div className="col col-6">
                <div style={{ padding: "10px" }}>
                  <Typography gutterBottom variant="h5" component="h2">
                  {serverData.name}
                  </Typography>
                  <Typography style={{ color: "grey" }}>
                    List: {serverData.list_ac_names}
                  </Typography>
                  
                  <Typography style={{ color: "grey" }}>
                    Segment: {serverData.segment_ac_names}
                  </Typography>

                  <Typography style={{ color: "grey" }}>
                    Type: {serverData.type}
                  </Typography>
                  {
                    serverData.type == 'mms' && <>
                      <Typography style={{ color: "grey" }}>
                        Subject: {serverData.subject}
                      </Typography>
                      <Typography style={{ color: "grey" }}>
                        File: 
                      </Typography>
                      <img src={serverData.file} width={100} />
                    </>
                  }
                  <Typography style={{ color: "grey" }}>
                    Message: {serverData.message}
                  </Typography>
                  {/* <CampaignSMSDetailedHistory
                    campaignId={row.id}
                    selectedlistcontacts={serverData.list_ac_names}
                    sms_senderid={serverData.sender_id}
                    sms_template={serverData.message}
                  /> */}
                </div>
              </div>
              <div className="col col-6">
               <div style={{ padding: "10px" }}>
                  <Typography gutterBottom variant="h5" component="h2" className="invisible">
                  {'No data '}
                  </Typography>
                  <Typography style={{ color: "grey" }}>
                    Sender ID: {serverData.sender_id}
                  </Typography>
                  <Typography style={{ color: "grey" }}>
                    Total contacts: {serverData.contactlogs.length}
                  </Typography>
                  
                  <Typography style={{ color: "grey" }}>
                    Success: {serverData.contactlogs.length /*filter(e => e.status == 1).length*/}
                  </Typography>

                  {/*<Typography style={{ color: "grey" }}>
                    In Progress : {serverData.contactlogs.filter(e => (
                      e.status == 0 ||
                      e.status == 3 ||
                      e.status == 4)).length}
                  </Typography>
                  <Typography style={{ color: "grey" }}>
                    Failed: {serverData.contactlogs.filter(e => e.status == 2).length}
                  </Typography>*/}
                  
                </div>
              </div>
            </div>
            {/* <CampaignSMSDetailedHistory
              campaignId={row.id}
              selectedlistcontacts={serverData.contactlogs}
              sms_senderid={serverData.sender_id}
              sms_template={serverData.message}
            /> */}
          </ExpansionPanelDetails>
        )}
      </React.Fragment>
    );
  };

  return (
    <div className="panal-viewport middle-container">
      <Header name={"SMS Campaign History"} />
        <Paper className={`${classes.root} pb-2`}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow id="table-head_rw">
                  {columns.map((column) => (
                    <StyledTableCell className="table-hd">
                      {column.label}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {serverData &&
                  serverData?.map((row) => (
                    <CampaignHistoryRow row={row} key={row.id} />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {pageCount > 0 && <div className={`${classes.root} mt-3 mb-3 d-flex justify-content-end`}>
                    <Pagination 
                      count={pageCount} 
                      page={page} 
                      onChange={handleChange} 
                      variant="outlined" 
                      shape="rounded"
                      />
                  </div>}
        </Paper>
    </div>
  );
}
