import React from "react";
import logo from "./logo.svg";
import Grid from "@material-ui/core/Grid";
import FeatureLayout from "./FeatureLayout";
import "./index.scoped.css";



export default function({data}) {
  // console.log(data)
  return (
    <Grid item xs={12} md={6} className="left-content">
      <div className="left-wrap">
        <div className="logo-container">
        { data?.whitelabel_logo && <img src={data?.whitelabel_logo} alt="logo" /> }
        </div>

        <div className="title-tab">
          <ul style={{ marginLeft: "20px", textTransform: "capitalize" }}>
            <li>No credit card required to sign up!</li>
            <li>Start with 100 FREE Credits to Trial!</li>
          </ul>
          <h4>Enhance the power of your {data?.whitelabel_name} platform with SMS</h4>
          {false && (
            <ul style={{ marginLeft: "20px", textTransform: "capitalize" }}>
              <li>100 FREE credits to trial the platform upon sign-up</li>
              <li>No credit card required to sign up</li>
              <li>
                a simple yet, effective SMS Add-On To your {data?.whitelabel_name}
                Platform!
              </li>
            </ul>
          )}
          {true && (
            <>
              <p>
                VYBRNT SMS is the ultimate SMS tool for {data?.whitelabel_name} users
              </p>
            </>
          )}
        </div>
        <FeatureLayout data={data} />
      </div>
    </Grid>
  );
}
