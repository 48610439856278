import React from "react";
import logger from "../../../logger";
import store from "./../../../Common/storage"

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Constants from "../../../Utils/Constants";
import axios from "axios";
import { getHeaders } from "../../../Utils/Common";

class ActiveCampaignForm extends React.Component {
  componentDidMount() {
    var elementExists = document.getElementById("script_form_79");
    if(!elementExists) {
      const script = document.createElement("script");
      script.src = store?.get('user')?.partner?.upgrade_plan_form_url || "https://vybrnt.activehosted.com/f/embed.php?id=79";
      script.async = true;
      script.id = 'script_form_79';
      document.body.appendChild(script);
    }
    // console.log(script)
  }

  componentWillUnmount() {
    // document.getElementById('special').remove()
  }

  render() {
    return <div id="embed_form_79_credit_btn" className="_form_79" />;
  }
}

export default function FormDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [credits, setCredits] = React.useState(0);

  Constants.openCredits = () => {
    setOpen(true);
  };

  React.useEffect(() => {
    
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <span
        className="c-button text-uppercase"
        onClick={handleClickOpen}
      >
        Upgrade plan
      </span>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent id="id894568">
          <ActiveCampaignForm />
        </DialogContent>
      </Dialog>
      
    </>
  );
}
