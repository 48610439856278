import React from "react";
import Paper from "@material-ui/core/Paper";
import ACConfigsSection from "./ACConfigsSection";
import LegalCenter from "./LegalCenter";
import Header from "./../../assets/components/header";
import Induction from "./../../Common/Induction";
import CXAppApiKeysSection from "./CXAppApiKeysSection";
import TwoWaySMSSection from "./TwoWaySMSSection";
import UnsubscribeCode from "./UnsubscribeCode";
import General from "./General";
import EduCenter from "./EduCenter";
import CountryCenter from "./CountryCenter"
import Users from "./Users"
import store from "./../../Common/storage"

export default function StickyHeadTable(props) {
  return (
    <div className="mb-50">
      <div className="panal-viewport">
      <Header name={"Settings"} />
      <Induction
        name={props.name}
        content={
          <ul style={{ listStyleType: "circle" }}>
            <li>
              <p>
                Connect to your {store?.get('user')?.partner?.whitelabel_name} account to send bulk SMS to your
                contacts
              </p>
            </li>
          </ul>
        }
      />
      </div>

      <Paper className="panal-width panal-viewport">
        <ACConfigsSection />
      </Paper>
      <br />
      <Paper className="panal-width panal-viewport">
        <CXAppApiKeysSection />
      </Paper>
      <br />
      <Paper className="panal-width panal-viewport">
        <General />
      </Paper> 
      <br />
      <Paper className="panal-width panal-viewport">
        <CountryCenter />
      </Paper> 
      <br />
      <Paper className="panal-width panal-viewport">
        <EduCenter />
      </Paper> 
      <br />
      <Paper className="panal-width panal-viewport">
        <LegalCenter />
      </Paper>
      <br />      
      <Paper className="panal-width panal-viewport">
        <TwoWaySMSSection />
      </Paper>
       
      <br />
      <Paper className="panal-width panal-viewport">
        <Users />
      </Paper>
      <br />
      <Paper className="panal-width panal-viewport">
        <UnsubscribeCode />
      </Paper>     
    </div>
  );
}
