import React from "react";
import logger from "./../../../logger";
import axios from "axios";
import store from "./../../../Common/storage"
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import Divider from "@material-ui/core/Divider";

import toastMaker from "./../../../Common/toastmaker";
import LoadingProgress from "./../../../Common/LoadingProgress";
import { getToken, getUser, getHeaders, saveUser } from "./../../../Utils/Common";
import Constants from "./../../../Utils/Constants";

import AddACConfigButton from "./AddACConfigButton";
import ActionButtonWithMenu from "./ActionButtonWithMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));


export default function ControlledExpansionPanels() {
  const user = getUser();
  // console.log(user)
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [acconfigs, setAcconfigs] = React.useState(user.configs);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // const addActiveCampaignConfig = (body) => {
  //   logger.log("addActiveCampaignConfig");
  //   if (acconfigs && acconfigs.length === 1) {
  //     toastMaker.makeToast("You can only add one acconfig!", {
  //       variant: "error",
  //     });
  //     return;
  //   }
  //   setIsLoading(true);
  //   return axios
  //     .post(Constants.acconfigApiPath, body, getHeaders())
  //     .then((res) => {
  //       const response = res.data;
  //       logger.log("data", response);
  //       const user = getUser();
  //       user.acconfigs = response.data;
  //       saveUser(user);
  //       setAcconfigs(response.data);
  //       setIsLoading(false);
  //       toastMaker.makeToast("Added new AC config!", {
  //         variant: "success",
  //       });
  //     })
  //     .catch((error) => {
  //       setIsLoading(false);
  //       toastMaker.makeToast("Some issue..", {
  //         variant: "error",
  //       });
  //     });
  // };

  // const onDeleteTemplateSubmit = (row) => {
  //   setIsLoading(true);
  //   return axios
  //     .delete(Constants.acconfigApiPath + "?id=" + row.id, getHeaders())
  //     .then((res) => {
  //       const response = res.data;
  //       //logger.log("data", response);
  //       const user = getUser();
  //       user.acconfigs = response.data;
  //       saveUser(user);
  //       setAcconfigs(response.data);
  //       setIsLoading(false);
  //       toastMaker.makeToast("Deleted AC Config..", {
  //         variant: "success",
  //       });
  //     })
  //     .catch((error) => {
  //       setIsLoading(false);
  //       toastMaker.makeToast("Some issue..", {
  //         variant: "error",
  //       });
  //     });
  // };

  const onEditTemplateSubmit = (row) => {
    // logger.log("onEditTemplateSubmit:", row);
    setIsLoading(true);
    axios.post(Constants.checkAcCredsApiPath, {
      host: row.host,
      key: row.api_key
    }, getHeaders())
          .then(response => {
            axios.put(Constants.getApiPathWithConfigId(Constants.configurationsApiPath), {
              alias: row.alias,
              host: row.host,
              api_key: row.api_key
            }, getHeaders())
                  .then(response => {
                    user.configs = [response.data.data]
                    saveUser(user)
                    setAcconfigs([response.data.data])
                    setIsLoading(false);
                  }).catch(e => {})
                  .catch(
                    error => toastMaker.makeToast("Something went wrong, please try again", {
                                          variant: "error",
                                    })
                    )
          })
          .catch(
            error => toastMaker.makeToast("Wrong details, Please check API URL and API KEY", {
                                  variant: "error",
                            })
            )
  };

  if (isLoading) {
    return <LoadingProgress />;
  }

  return (
    <ExpansionPanel
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography className={classes.heading}>
          <span className="mb-10"><b>{store?.get('user')?.partner?.whitelabel_name} Configs</b></span>
        </Typography>
        <Typography className={classes.secondaryHeading}>
          Add Your {store?.get('user')?.partner?.whitelabel_name} Account
          <br />
          Set and review {store?.get('user')?.partner?.whitelabel_name} Accesses here
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ display: "block" }}>
        {acconfigs &&
          acconfigs.map((config) => {
            return (
              <tr>
                <td style={{ width: "100%" }}>
                  <div style={{ padding: "10px" }}>
                    <Typography style={{ fontWeight: "bold" }}>
                      {config.alias}
                    </Typography>
                    <Typography style={{ color: "grey", wordBreak: "break-all" }}>
                      {config.host}
                    </Typography>
                    <Typography style={{ color: "grey", wordBreak: "break-all" }}>
                      {config.api_key}
                    </Typography>
                  </div>
                </td>
                <td>
                  <div className="o-icon">
                    <ActionButtonWithMenu
                      row={config}
                      // onDeleteTemplateSubmit={onDeleteTemplateSubmit}
                      onEditTemplateSubmit={onEditTemplateSubmit}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
      </ExpansionPanelDetails>
      {(!acconfigs || (acconfigs && acconfigs.length === 0)) && (
        <div>
          <Divider />
          <ExpansionPanelActions>
            {/* <AddACConfigButton
              addActiveCampaignConfig={addActiveCampaignConfig}
            /> */}
          </ExpansionPanelActions>
        </div>
      )}
    </ExpansionPanel>
  );
}
