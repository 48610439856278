import React from "react";
//import { withRouter } from "react-router-dom";

import Grid from "@material-ui/core/Grid";

import Header from "./../../assets/components/header";
import Icon from "./../../assets/components/icon";
import Constants from "./../../Utils/Constants";
import Induction from "./../../Common/Induction";
import store from "./../../Common/storage.js";
import toastMaker from "./../../Common/toastmaker.js";
import FeedbackButton from "./FeedbackButton";
import StrategyPortalButton from "./StrategyPortalButton";
import QuickStartVideoDialog from "./QuickStartVideoDialog";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import HelpIcon from '@material-ui/icons/Help';
import "./index.css";
import axios from "axios";
import { getHeaders, setUserSession } from "./../../Utils/Common";
import { useClipboard } from "use-clipboard-copy";
import FileCopyIcon from '@material-ui/icons/FileCopy';


function MediaCard(props) {

  const [alias, setAlias] = React.useState(store.get('user').configs[store.get("c_id_index") || 0]?.alias)
  const [host, setHost]   = React.useState(store.get('user').configs[store.get("c_id_index") || 0]?.host)
  const [key, setKey]     = React.useState(store.get('user').configs[store.get("c_id_index") || 0]?.api_key)

  const submitSetup = () => {
    // console.log('alias', alias, 'host', host, 'key', key)
    if(alias == '' || host == '' || key == '') {
      toastMaker.makeToast("All fields are mandatory", {
            variant: "error",
      });
    } else {
      axios.post(Constants.checkAcCredsApiPath, {
        host: host,
        key: key
      }, getHeaders())
            .then(response => {
              axios.put(Constants.getApiPathWithConfigId(Constants.configurationsApiPath), {
                alias: alias,
                host: host,
                api_key: key
              }, getHeaders())
                    .then(response => {
                      axios.get(Constants.userApiPath, getHeaders())
                                .then(r => {
                                  let data = r.data.data
                                  setUserSession(store.get('token'), store.get('refresh_token'), store.get('expires_in'), data, props.history);
                                  toastMaker.makeToast("Welcome to VYBRNT SMS!", {
                                    variant: "success",
                                  })
                                  Constants.navigate(Constants.dashboard)

                                })
                                .catch(e => console.log(e, 'e'))
                    }).catch(e => {})
                    .catch(
                      error => toastMaker.makeToast("Something went wrong, please try again", {
                                            variant: "error",
                                      })
                      )
            })
            .catch(
              error => toastMaker.makeToast("Wrong details, Please check API URL and API KEY", {
                                    variant: "error",
                              })
              )
    }
  }

  const clipboard = useClipboard({
    onSuccess() {
      toastMaker.makeToast(
        `Great! Now you can paste API Key in your ${store.get('user')?.partner?.whitelabel_name} app setup.`,
        {
          variant: "success",
        }
      );
      //logger.log("Text was copied successfully!");
    },
    onError() {
      toastMaker.makeToast("'Failed to copy API Key!'", {
        variant: "error",
      });
      //logger.log("Failed to copy text!");
    },
  });

  const manageConfig = (c, i) => {
    store.set('c_id', c?.id)
    setAlias(c?.alias)
    setHost(c?.host)
    setKey(c?.api_key)
    store.set("c_id_index", i)
  }

  const content = (
    <ul style={{ listStyleType: "circle" }} dangerouslySetInnerHTML={{__html: store.get('user')?.partner?.welcome_panel_home}}>
      

    </ul>
  );

  if(store.get('user').configs.length > 1 && !store.get('c_id')) {
    return (
    <>
      <h1>Please select account</h1>
      <ul>
      {
        store.get('user').configs.map((c, i) => <li key={c.id} ><a href="#" onClick={() => manageConfig(c, i)} > {`${c.name}`} </a></li>)
      }
      </ul>
    </>)
  }

  if(store.get('user').configs[store.get("c_id_index")]?.host == '' || store.get('user').configs[store.get("c_id_index")]?.api_key == '') {
    if(store.get('user').partner.partner_user_id == store.get('user').id) {
    return (
      <div>
        <Header name={"Setup"} />  
        <Induction name={props.name} content={<>
          <p>How to?</p>
          <ol>
            <li>Alias is the name of your business or {store.get('user')?.partner?.whitelabel_name} account. This can be anything you want.</li>
            <li>Login to your {store.get('user')?.partner?.whitelabel_name} account.</li>
            <li>Click on Settings (gear icon) in the menu.</li>
            <li>Navigate to Developer.</li>
            <li>Copy the URL and paste it into the API URL below.</li>
            <li>Copy the Key and paste it into the API Key below.</li>
          </ol> 
          <p> Tip </p>
          <ul>
            <li>In case you do not have an {' '}
              <a href={store.get('user')?.partner?.whitelabel_link} target="_blank" > 
              ${store.get('user')?.partner?.whitelabel_name} account, click here to create a new one.
              </a>
              </li>
          </ul>
            
          </>
        } />
        <div>
          <Paper className="account-details"
            elevation={3}
            style={{
              margin: "0 auto",
              maxWidth: "700px",
              padding: "10px",
              marginBottom: "100px",
            }}
          >
            <div>
              <Typography className={''}>
                Details
              </Typography>

              <form>
                <div className="form-groups">
                  <label>Alias 
                    <div class="tooltip ml-2">
                      <HelpIcon />
                      <span class="tooltiptext">
                          The name of your business or {store.get('user')?.partner?.whitelabel_name} account. This can be anything you want.
                      </span>
                    </div>
                  </label>
                  <input
                    id="alias"
                    value={alias}
                    onChange={(event) => {
                      setAlias(event.target.value);
                    }}
                    type="text"
                    className="form-controls"
                    placeholder="Alias"
                  />
                </div>

                <div className="form-groups">
                  <label>API URL</label>  
                  <input
                    id="host"
                    value={host}
                    onChange={(event) => {
                      setHost(event.target.value);
                    }}
                    type="text"
                    className="form-controls"
                    placeholder="API URL"
                  />
                </div>

                <div className="form-groups">
                  <label>API Key</label>  
                  <input
                    id="key"
                    value={key}
                    onChange={(event) => {
                      setKey(event.target.value);
                    }}
                    type="text"
                    className="form-controls"
                    placeholder="API Key"
                  />
                </div>

                <div className="form-groups mt-5">
                  <span
                  className="c-button text-uppercase"
                  style={{ marginRight: "10px" }}
                  onClick={submitSetup}
                >
                  Submit
                </span>
              </div>

              </form>
            </div>
          </Paper>

        </div>
      </div>
    )
    } else {
      if(store.get('user').partner.unverified_user_landing_page == 'setup') {
        return <div>
          <Header name={"Setup"} />  
          <Induction name={props.name} content={<>
            <p>How to?</p>
            <ol>
              <li>Alias is the name of your business or {store.get('user')?.partner?.whitelabel_name} account. This can be anything you want.</li>
              <li>Login to your {store.get('user')?.partner?.whitelabel_name} account.</li>
              <li>Click on Settings (gear icon) in the menu.</li>
              <li>Navigate to Developer.</li>
              <li>Copy the URL and paste it into the API URL below.</li>
              <li>Copy the Key and paste it into the API Key below.</li>
            </ol> 
            <p> Tip </p>
            <ul>
              <li>In case you do not have an {' '}
                <a href={store.get('user')?.partner?.whitelabel_lonk} target="_blank" > 
                {store.get('user')?.partner?.whitelabel_name} account, click here to create a new one.
                </a>
                </li>
            </ul>
              
            </>
          } />
          <div>
            <Paper className="account-details"
              elevation={3}
              style={{
                margin: "0 auto",
                maxWidth: "700px",
                padding: "10px",
                marginBottom: "100px",
              }}
            >
              <div>
                <Typography className={''}>
                  Details
                </Typography>

                <form>
                  <div className="form-groups">
                    <label>Alias 
                      <div class="tooltip ml-2">
                        <HelpIcon />
                        <span class="tooltiptext">
                            The name of your business or {store.get('user')?.partner?.whitelabel_name} account. This can be anything you want.
                        </span>
                      </div>
                    </label>
                    <input
                      id="alias"
                      value={alias}
                      onChange={(event) => {
                        setAlias(event.target.value);
                      }}
                      type="text"
                      className="form-controls"
                      placeholder="Alias"
                    />
                  </div>

                  <div className="form-groups">
                    <label>API URL</label>  
                    <input
                      id="host"
                      value={host}
                      onChange={(event) => {
                        setHost(event.target.value);
                      }}
                      type="text"
                      className="form-controls"
                      placeholder="API URL"
                    />
                  </div>

                  <div className="form-groups">
                    <label>API Key</label>  
                    <input
                      id="key"
                      value={key}
                      onChange={(event) => {
                        setKey(event.target.value);
                      }}
                      type="text"
                      className="form-controls"
                      placeholder="API Key"
                    />
                  </div>

                  <div className="form-groups mt-5">
                    <span
                    className="c-button text-uppercase"
                    style={{ marginRight: "10px" }}
                    onClick={submitSetup}
                  >
                    Submit
                  </span>
                </div>

                </form>
              </div>
            </Paper>

          </div>
        </div>
      } else {
        return <div>
        <Header name={"Setup"} />  
        <div>
          <Paper className="account-details"
            elevation={3}
            style={{
              margin: "0 auto",
              maxWidth: "700px",
              padding: "10px",
              marginBottom: "100px",
            }}
          >
            <div>
              <Typography className={''}>
                Your account configuration is pending.
              </Typography>
            </div>
          </Paper>

        </div>
      </div>
      }
    }
  }

  return (
    <div>
      <Header name={"Dashboard"} />      
      <Induction name={props.name} content={content} />
      <Grid container spacing={3} className="grid-bottom-spacing">
        <Grid item xs={12} sm={6} md={3} lg={3} className="zoom">
          <div className="sms-auto_tab">
            <h2>SMS</h2>
            <h2>Templates</h2>
            <p>
              Easily Create SMS Message Copy, Label And Organize Them 
              For All Your Campaigns And Nurture Sequences.
            </p>
            <Icon
              className="zoombutton"
              name="send-mail"
              onClick={() => {
                Constants.navigate(Constants.smstable);
              }}
            />
            <div className="clear"></div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}  className="zoom">
          <div className="sms-auto_tab">
            <h2>sms</h2>
            <h2>Campaigns</h2>
            <p>
              {/* Easily create an SMS campaign. Send a promotion out to a Synched
              ActiveCampaign list. */}
              Easily Send An SMS Or MMS Campaign To One Of 
              Your {store.get('user')?.partner?.whitelabel_name} Lists And/Or Segments.
            </p>
            <Icon
              className="zoombutton"
              name="send-mail"
              onClick={() => {
                Constants.navigate(Constants.smsautomation);
              }}
            />
            <div className="clear"></div>
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={3}  className="zoom">
          <div className="sms-auto_tab">
            <h2>TRAINING</h2>
            <h2>CALL</h2>
            <p>
              Book A Complimentary 1:1 Call With A VYBRNT Representative 
              For SMS Or {store.get('user')?.partner?.whitelabel_name} Optimization Support.
              
            </p>
            <Icon
              className="zoombutton"
              name="send-mail"
              onClick={() => {
                window.open("tel:+61450611664");
              }}
            />
            <div className="clear"></div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}  className="zoom">
          <div className="sms-auto_tab">
            <h2>SHARE</h2>
            <h2>{store.get('user')?.partner?.whitelabel_name}</h2>
            <p>
              Want To Share {store.get('user')?.partner?.whitelabel_name} With A Friend? Here's A Link To A Free Trial.
            </p>
            <Icon
              className="zoombutton"
              name="send-mail"
              onClick={() => {
                window.open(`mailto:?subject=Here's a Free Trial to ${store.get('user')?.partner?.whitelabel_name}.&body=Hey,%0D%0A %0D%0A Here's a link to a free trial of ${store.get('user')?.partner?.whitelabel_name}: ${store.get('user')?.partner?.whitelabel_link}, %0D%0A%0D%0AUnlock the potential of your sales and marketing strategies with ${store.get('user')?.partner?.whitelabel_name}, a robust CRM system designed to streamline your processes and drive growth.%0D%0A%0D%0A ${store.get('user')?.partner?.whitelabel_link} %0D%0A %0D%0ABest Regards,`)
              }}
            />
            <div className="clear"></div>
          </div>
        </Grid>
      </Grid>
      <FeedbackButton />
      <StrategyPortalButton />
      <Icon
        alt="logo"
        name={"vybrnt-footer-logo"}
        className="footer-logo"
      />
    </div>
  );
}

// export default withRouter(MediaCard);
export default MediaCard;
