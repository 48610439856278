import store from "./../../Common/storage";
import logger from "./../../logger";
import axios from 'axios'
import sha256 from 'sha256';

export const hashString = plaintext => {
  const hashed = sha256(plaintext);
  return hashed;
}

// return the user data from the session storage
export const getUser = () => {
  return store.get("user") || null;
};

// return the token from the session storage
export const getToken = () => {
  logger.log("Get token called returning", store.getStore());
  return store.get("token") || null;
};

// remove the token and user from the session storage
export const removeUserSession = () => {
  logger.log("removeUserSession before", store.getStore());
  store.clear();
  logger.log("removeUserSession after", store.getStore());
};

export const setUserTokens = (accesstoken, refreshtoken, expires_in) => {
  logger.log("token", accesstoken);
  logger.log("setUserSession before", store.getStore());
  store.clear();
  store.set("token", accesstoken);
  store.set("refreshtoken", refreshtoken);
  store.set("expires_in", expires_in);
  logger.log("setUserSession after 1", store.getStore());
};

// set the token and user from the session storage
export const setUserSession = (accesstoken, refreshtoken, expires_in, user, history) => {
  logger.log("user", user);
  logger.log("setUserSession before", store.getStore());
  store.clear();
  // store.set("token", user.accesstoken);
  store.set("token", accesstoken);
  store.set("refreshtoken", refreshtoken);
  store.set("expires_in", expires_in);
  store.set("user", user);
  store.set("c_id_index", 0);
  if(user.configs.length == 1) {
    store.set("c_id", user.configs[0].id);
  }
  logger.log("setUserSession after", store.getStore());
};

export const saveUser = (user) => {
  store.set("user", user);
};

export const getHeaders = () => {
  return {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
};
