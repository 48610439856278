import React from "react";
import logger from "./../../../logger";
import store from "./../../../Common/storage"

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Constants from "./../../../Utils/Constants";
import axios from "axios";
import { getHeaders } from "./../../../Utils/Common";

class ActiveCampaignForm extends React.Component {
  componentDidMount() {
    var elementExists = document.getElementById("script_form_71");
    if(!elementExists) {
      const script = document.createElement("script");
      script.src = store?.get('user')?.partner?.credits_form_url || "https://vybrnt.activehosted.com/f/embed.php?id=71";
      script.async = true;
      script.id = 'script_form_71';
      document.body.appendChild(script);
    }
    // console.log(script)
  }

  componentWillUnmount() {
    // document.getElementById('special').remove()
  }

  render() {
    return <div id="embed_form_71_credit_btn" className="_form_71" />;
  }
}

export default function FormDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [credits, setCredits] = React.useState(0);

  Constants.openCredits = () => {
    setOpen(true);
  };

  const getCredits = () => { 
    return axios.get(Constants.getCreditsApiPath, getHeaders()).then((res) => {
      const response = res.data;
      logger.log("credits data", response);
      const creds = response.data.credits;
      if (creds !== null && creds !== credits) {
        setCredits(creds);
      }
    }).catch(e => console.error('error', e));
  };

  let timer;

  React.useEffect(() => {
    getCredits();
    timer = setInterval(() => getCredits(), 100000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <span
        className="c-button c-button--secondary text-uppercase"
        onClick={handleClickOpen}
      >
        Credits : {credits}
      </span>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent id="id894567">
          <ActiveCampaignForm />
        </DialogContent>
      </Dialog>
      
    </>
  );
}
